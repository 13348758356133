.textBlock {
  // background-position: 100%;
  // background-size: 70%;
  // background-image: url('../../assets/back1.png');
  display: flex;
  height: 400px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0 16px 0 16px;
}

.column {
  grid-template-columns: 1fr;
  grid-template-rows: 3fr 4fr 1fr;
}

.row {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.line {
  width: 100%;
  height: 1px;
  background: #574c4c;
  box-shadow: -2px -10px 20px 0px rgb(209, 207, 207);
}

.single {
  max-width: 800px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  align-items: center;
  margin: 0 auto;
  color: rgb(62, 62, 75);
}

.root {
  max-width: 1440px;
  width: 1440px;
  min-height: 100vh;
  margin: 0 auto;

  @media (min-width: 768px) and (max-width: 991.98px) {
    width: 720px;
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 540px;
  }
  @media (min-width: 576px) {
    margin-right: auto;
    margin-left: auto;
  }
  @media (max-width: 575.98px) {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
  }
  @media (max-width: 320px) {
    width: 100%;
    padding-right: 8px;
    padding-left: 8px;
  }
}

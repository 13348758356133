.contentBlock {
  display: grid;
  overflow: hidden;
  grid-template-rows: auto;
  grid-template-columns: auto;
  padding: 80px 0;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
}

.row {
  grid-template-columns: 1fr 1fr;
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
}
.column {
  grid-template-rows: 1fr 1fr;
  @media (max-width: 500px) {
    grid-template-rows: 1fr;
  }
}
.gap- {
  gap: 0;
}

.gap-4 {
  gap: 4px;
}
.gap-8 {
  gap: 8px;
}
.gap-16 {
  gap: 16px;
}

.oneBlock {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.twoBlock {
}

.bg-template {
  background: rgba($color: #000000, $alpha: 1);

  background-image: url('https://www.www');
  background-position: 100%;
  background-size: 70%;
}

.sky_blue {
  background: rgba(247, 255, 244, 0.192);
  box-shadow: 0 1px 1px 1px rgb(187, 238, 187);
}

.footer {
  min-height: 100px;
  height: 278px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

  // margin: 0 24px;

  background-color: rgb(243, 241, 241);
  color: rgb(68, 65, 65);

  position: relative;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-content: center;
    align-content: center;
    gap: 12px;
    padding-bottom: 12px;
    height: auto;
  }
}

.footer_item {
  display: flex;

  justify-content: space-between;
  align-items: center;
  text-align: center;
  gap: 18px;
  div {
    padding: 52px;
  }
  .footer_item_text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    list-style: 48px;
    padding-top: 32px;
  }

  &:first-child {
    border-bottom: 1px solid rgb(255, 255, 255);
  }

  @media (max-width: 500px) {
    flex-direction: column;
    div {
      padding: 12px;
    }
  }
}

.footer::before {
  content: '';
  position: absolute;
  top: -12px;
  width: 100%;
  height: 1px;
  background-color: rgba(4, 155, 249, 0.2);
  box-shadow: 0 0 25px 1px rgba(24, 152, 226, 0.842);
}

a {
  text-decoration: none;
  letter-spacing: 0.05em;
  color: rgb(104, 103, 109);
  transition: 0.3s;
}
a:hover {
  text-decoration: underline;
  color: rgb(91, 91, 204);
  transition: 0.3s;
}

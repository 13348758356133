.layout {  
  display: grid;  
  grid-template-rows: auto 1fr auto;
  min-height: 100vh; 

  @media (max-width:500px) {
     gap: 40px; 
  }
 

  // @media (max-width: 600px) {
  //   gap: 40px;
  // }
}

